port module Main exposing (main)

-- PATIENT --
-- ????: How / when to load sessions?
-- IDEA: Today's patients on top (with a separator) by session order (show time on name?).
-- IDEA: Put next session date on the name of the patient.
-- IDEA: Option to hide patients with no scheduled session (filters like HG workbench?).

-- OTHER --
-- IDEA: Offline mode... should data be cached somehow on IndexedDB? Is this secure?
-- TODO: SVG icons could be done in code... (https://github.com/Orasund/elm-ui-widgets/blob/master/example/src/Icons.elm)
-- TODO: Can the side panel be generalized to be more independent of the app?
-- TODO: Allow the movement among the patients in the side panel using the keyboard up/down keys?

import Browser
import Browser.Events
import Codec as Codec
import Date exposing (Date)
import Dict exposing (Dict)
import Element exposing (..)
import Element.Keyed as Keyed
import Html exposing (Html)
import Json.Encode as E
import Loading exposing (LoaderType(..), defaultConfig)

import App
import Data as Data
import Home
import Shared exposing (Shared)
import Util exposing (onClick)

-- Ports

port signedIn : (E.Value -> msg) -> Sub msg
port signedOut : (() -> msg) -> Sub msg

port setToday : (E.Value -> msg) -> Sub msg

-- Model

type Model
    = AnonymousUser Home.Model
    | Loading Shared  -- FIX: not sure if this is a useful state...
    | SignedInUser App.Model

mapShared : (Shared -> Shared) -> Model -> Model
mapShared f m =
    case m of
        AnonymousUser home -> AnonymousUser <| { home | shared = (f home.shared) }
        Loading s -> Loading <| f s
        SignedInUser app -> SignedInUser <| { app | shared = (f app.shared) }

-- INIT

type alias Flags =
    { height : Int
    , width : Int
    , todayDay : Int
    , todayMonth : Int
    , todayYear : Int
    , urlHomeProduto01 : String
    , urlHomeProduto02 : String
    , urlLogo : String
    , urlLogoNome : String
    }

init : Flags -> ( Model, Cmd Msg )
init flags =
    ( Loading
        { width = flags.width
        , height = flags.height
        , today = Date.fromCalendarDate flags.todayYear (Date.numberToMonth flags.todayMonth) flags.todayDay
        , urlHomeProduto01 = flags.urlHomeProduto01
        , urlHomeProduto02 = flags.urlHomeProduto02
        , urlLogo = flags.urlLogo
        , urlLogoNome = flags.urlLogoNome
        }
    , Cmd.none
    )

-- Update

type Msg
    = NoOp
    | GotAppMsg App.Msg
    | GotHomeMsg Home.Msg
    | TodayChanged Date
    | UserSignedIn Data.FBUser
    | UserSignedOut
    | WindowResized Int Int

update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case (model, msg) of
        (_, TodayChanged d) ->
            (mapShared (\s -> { s | today = d }) model, Cmd.none)
        (_, WindowResized w h) ->
            (mapShared (\s -> { s | height = h, width = w }) model, Cmd.none)
        (AnonymousUser m, UserSignedIn user) ->
            (SignedInUser (App.init m.shared user), Cmd.map GotAppMsg (App.load m.shared user))
        (AnonymousUser homeModel, GotHomeMsg homeMsg) ->
            let
                (newHomeModel, newHomeCmd) = Home.update homeMsg homeModel
            in
                (AnonymousUser newHomeModel, Cmd.map GotHomeMsg newHomeCmd)
        (Loading s, UserSignedIn user) ->
            (SignedInUser (App.init s user), Cmd.map GotAppMsg (App.load s user))
        (Loading s, UserSignedOut) ->
            (AnonymousUser (Home.init s), Cmd.none)
        (SignedInUser appModel, GotAppMsg appMsg) ->
            let
                (newAppModel, newAppCmd) = App.update appMsg appModel
            in
                (SignedInUser newAppModel, Cmd.map GotAppMsg newAppCmd)
        (SignedInUser m, UserSignedIn user) ->
            (SignedInUser { m | user = user }, Cmd.none)
        (SignedInUser m, UserSignedOut) ->
            (AnonymousUser (Home.init m.shared), Cmd.none)
        _ ->
            (model, Cmd.none)

-- View

viewLoading : Element Msg
viewLoading =
    Element.el [ centerX, centerY ]
        <| Element.html
        <| Loading.render Spinner { defaultConfig | color = "#303E4D", size = 50 } Loading.On

view : Model -> Html Msg
view m =
    layout [ height fill, width fill ] <|
        Keyed.el [ height fill, width fill ] <|
            case m of
                AnonymousUser model ->
                    ("home", Element.map GotHomeMsg <| Home.view model)
                Loading _ ->
                    ("loading", viewLoading)
                SignedInUser model ->
                    ("app", Element.map GotAppMsg <| App.view model)

-- Subs

subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ signedIn (\value ->
            case Codec.decodeValue Data.codecFBUser value of -- D.decodeValue decoder value of
                Ok user ->
                    UserSignedIn user
                Err _ ->
                    -- For some reason, the JSON value could not be converted to a FBUser value.
                    -- TODO: Handle the error (think about the UX)?
                    UserSignedOut)
        , signedOut (\_ -> UserSignedOut)
        , setToday (\value ->
            case Codec.decodeValue Data.codecToday value of
                Ok today ->
                    TodayChanged <| Date.fromCalendarDate today.year (Date.numberToMonth today.month) today.day
                Err _ ->
                    -- TODO: Handle the error... not sure when it can happen... or what to do.
                    NoOp)
        , Browser.Events.onResize WindowResized
        ]

-- Main

main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view
        }
